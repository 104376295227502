.container {
  input {
    background: transparent;
    box-shadow: none;
    border: 1px solid #c1cbdd;
    border-radius: 100px;
    color: #fff;
    font-size: 1rem;
    padding: 14px 24px;
  }
}
