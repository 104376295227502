.eec-investors {
  &--hero {
    position: relative;
    padding: 156px 0 86px 0;
    margin-bottom: 80px;

    .hero {
      &__title {
        font-weight: 700;
        font-size: 56px;
        margin-bottom: 24px;
      }

      &__description {
        font-weight: 400;
        font-size: 24px;
        letter-spacing: 0.15px;
        color: #ffffff;
        opacity: 0.5;
        margin-bottom: 40px;
      }

      @include media-breakpoint-down(lg) {
        &__title {
          font-size: 40px;
        }
        &__description {
          font-size: 20px;
        }
      }
    }
  }

  &--grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @include media-breakpoint-down(lg) {
      gap: 32px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(md) {
      gap: 20px;
      grid-template-columns: 1fr;
    }
  }
}
