.post-feed {
  position: relative;
  display: grid;
  gap: 4.8vmin 4vmin;
  grid-template-columns: repeat(6, 1fr);
  padding: max(4.8vmin, 36px) 0 0;
}

:is(.tag-template, .author-template) .post-feed {
  margin-top: 4vmin;
}

@media (max-width: 991px) {
  .post-feed {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .post-feed {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
}

.post-card {
  position: relative;
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  background-size: cover;
  word-break: break-word;
}

.post-card-image-link {
  position: relative;
  overflow: hidden;
  display: block;
  margin-bottom: 32px;
}

.post-card-image-link::after {
  content: "";
  display: block;
  padding-bottom: 55%;
}

.post-card[class*="post-access-"] .post-card-image-link::after {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}

.post-card.keep-ratio[class*="post-access-"] .post-card-image-link::after {
  position: absolute;
  inset: 0;
  padding-bottom: 0;
}

.post-card.keep-ratio:not(.post-card-large):not(.post-card-full) .post-card-image-link::after {
  padding-bottom: 0;
}

.post-card-image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: var(--color-lightgrey) no-repeat center center;
  object-fit: cover;
}

.post-card.keep-ratio:not(.post-card-large):not(.post-card-full) .post-card-image {
  position: static;
}

.post-card-access {
  position: absolute;
  inset: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
}

.post-card-content-link {
  position: relative;
  display: block;
  color: var(--color-darkgrey);
}

.post-card-content-link:hover {
  text-decoration: none;
}

.post-feed .no-image .post-card-content-link {
  padding: 0;
}

.no-image .post-card-header {
  margin-top: 0;
}

.post-card-tags {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 0 10px;
  color: var(--color-secondary-text);
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.005em;
  line-height: 1;
}

.post-card-featured {
  position: relative;
  display: flex;
  align-items: center;
  gap: 3px;
  padding-left: 18px;
  color: var(--ghost-accent-color);
}

.post-card-featured svg {
  position: absolute;
  left: 0;
}

.post-card-title {
  margin: 0;
  font-size: 2.6rem;
  font-weight: 800;
}

.post-card-content-link:hover .post-card-title {
  opacity: 0.9;
}

.no-image .post-card-title {
  margin-top: 0;
}

.has-serif-title .post-card-title {
  font-family: var(--font-serif);
  letter-spacing: -0.005em;
}

.post-card-title svg {
  margin-top: -3px;
  margin-left: -1px;
}

.post-card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.post-card-excerpt {
  display: -webkit-box;
  overflow-y: hidden;
  margin-top: 12px;
  max-width: 720px;
  font-size: 1.6rem;
  line-height: 1.5;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.has-sans-body .post-card-excerpt {
  font-family: var(--font-sans);
}

.post-card:not(.post-card-large):not(.post-card-full):not(.dynamic):not(.no-image) .post-card-excerpt {
  -webkit-line-clamp: 2;
}

:is(.tag-template, .author-template) .post-card-excerpt {
  margin-top: 6px;
}

.post-card-meta {
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding: 0;
  font-size: 1.3rem;
  color: var(--color-secondary-text);
}

.post-card-meta .sep {
  margin: 0 4px;
}

.author-profile-image {
  display: block;
  width: 100%;
  height: 100%;
  background: var(--color-lightgrey);
  border-radius: 100%;

  object-fit: cover;
}

.author-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 4px;
  padding: 0;
  list-style: none;
}

.author-list-item {
  position: relative;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
}


/* Special Styling for home page grid (below):

The first post in the list is styled to be bigger than the others and take over
the full width of the grid to give it more emphasis. Wrapped in a media query to
make sure this only happens on large viewports / desktop-ish devices.

*/

@media (min-width: 1001px) {
  .post-card-large {
    grid-column: span 6;
    display: grid;
    grid-gap: 4vmin;
    grid-template-columns: repeat(3, 1fr);
    border-top: 0;
  }

  .post-card-large:not(.no-image) .post-card-header {
    margin-top: 0;
  }

  .post-card-large .post-card-image-link {
    position: relative;
    grid-column: span 2;
    margin-bottom: 0;
  }

  .post-card-large .post-card-content {
    grid-column: span 1;
  }

  .post-card-large.no-image .post-card-content {
    grid-column: span 2;
  }

  .post-card-large .post-card-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .post-card-large .post-card-tags {
    margin-bottom: 12px;
  }

  .post-card-large .post-card-title {
    margin-top: 0;
    font-size: 4.4rem;
    line-height: 1;
  }

  .post-card-large .post-card-excerpt {
    margin-top: 16px;
  }

  .post-card-full {
    grid-column: span 6;
  }

  .post-card-full .post-card-image-link {
    margin-bottom: 40px;
  }

  .post-card-full .post-card-tags {
    margin-bottom: 14px;
  }

  .post-card-full .post-card-title {
    font-size: 6.4rem;
    line-height: 0.95;
  }

  .post-card-full .post-card-excerpt {
    margin-top: 20px;
    font-size: 1.8rem;
  }

  .post-card-large + .post-card-large:nth-child(even) {
    margin: 32px 0;
  }

  .post-card-large + .post-card-large:nth-child(even) .post-card-content {
    order: -1;
  }

  .post-card.dynamic {
    grid-column: span 3;
  }

  .post-card.dynamic .post-card-title {
    font-size: 3rem;
  }
}
