.eec-stay-updated {
  &--thanks {
    opacity: 0;
    transition: all 0.3s;
    height: 0;
    overflow: hidden;

    &.active {
      color: var(--bs-primary);
      margin-bottom: 16px;
      opacity: 1;
      height: auto;
    }

    &.error {
      color: var(--bs-danger);
      margin-bottom: 16px;
      opacity: 1;
      height: auto;
    }
  }
}
