.navbar {
  background-color: #0e0e0e;
  transition: all 0.3s ease-in-out;
  padding-left: 40px;
  padding-right: 40px;

  .navbar-brand {
    color: #ffffff;
  }

  .nav-link {
    color: #ffffff;

    &.active {
      color: #ffffff;
      font-weight: 700;
    }
  }

  .navbar-header {
    display: inline-flex;
    align-items: center;

    > button {
      margin-left: 0.75rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .navbar-toggler {
    padding: 0;
    border: none;
    color: #ffffff;

    &:focus {
      box-shadow: none !important;
      outline: none;
    }
  }
}

@media (max-width: 767.98px) {
  .navbar {
    padding-left: 0;
    padding-right: 0;
  }
}
