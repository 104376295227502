.header {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;

  z-index: 100;

  transition: background-color 0.5s, top 0.3s;

  &--background {
    position: absolute;
    background-color: #0e0e0e70;
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &-content {
    height: 70px;

    display: flex;
    justify-content: space-between;

    align-items: center;

    &--logo {
      position: relative;
      z-index: 25;
    }

    &--nav {
      display: flex;
      align-items: center;
    }
  }

  .logo-color {
    transition: fill 0.533s;
  }

  .logo-text-color {
    fill: white;
    transition: fill 0.5s;
  }

  .hamburger {
    display: none;
    position: relative;
    z-index: 25;

    &:hover {
      opacity: 1;
    }

    &-inner {
      &,
      &::before,
      &::after {
        background-color: white !important;
        transition-property: all;
      }
    }

    outline: none;
  }

  @media screen and (max-width: 768px) {
    .hamburger {
      display: flex;
    }

    &-content--nav {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 100%;

      transition: bottom 0.5s;

      overflow: hidden;
      background-color: #000;

      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      .nav-link {
        margin-left: 0 !important;
        padding-left: 0 !important;

        &::before {
          content: none !important;
        }
      }

      & > * {
        margin-bottom: 50px;
      }
    }

    .hamburger.is-active + &-content--nav {
      bottom: 0;
    }
  }
}

.eec-layout {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;

  .eec-content {
    margin-bottom: auto;
    width: 100%;
    height: 100%;

    &.--center {
      margin: auto;
    }
  }

  .full-bleed {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}
