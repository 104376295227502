.link {
  text-decoration: none;
  cursor: pointer;

  &.--secondary {
    color: $color-secondary;
  }
}

.nav-link {
  color: white;
  position: relative;

  height: 21px;

  white-space: nowrap;

  &:not(.--no-bottom-animation) {
    .nav-link--animation {
      position: relative;

      &::after {
        position: absolute;

        bottom: -6px;
        left: 51%;
        right: 51%;
        height: 2px;

        border-radius: 88px;

        background: linear-gradient(
          90deg,
          #fff 0%,
          #fff 48.96%,
          #ffffff80 100%
        );

        content: '';

        transition: left 0.2s, right 0.2s, background 0.5s;
      }
    }

    &:active,
    &:hover {
      .nav-link--animation {
        &::after {
          left: 25%;
          right: 25%;
        }
      }
    }

    &.active {
      .nav-link--animation {
        font-weight: bold;
      }
    }
  }
}

.nav-link + .nav-link {
  margin-left: 24px;
  padding-left: 24px;

  position: relative;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    width: 1px;

    background-color: white;
    opacity: 0.15;

    content: '';
  }
}

.--theme-light {
  .header {
    .nav-link {
      color: $color-navy;
    }
  }
}

.--theme-primary {
  .nav-link {
    &--animation {
      &::after {
        background: #5225a7;
      }
    }
  }
}

[class*='article-theme'] {
  .nav-link {
    &--animation {
      &::after {
        background: #5225a7 !important;
      }
    }
  }
}
