.eec-home {
  &--hero {
    padding: 156px 0 86px 0;
    position: relative;
    z-index: 0;
    margin-bottom: 80px;

    h1,
    p {
      line-height: 130%;
    }

    h1 {
      font-size: 56px;
      font-weight: 700;
      letter-spacing: -1px;
    }

    p {
      color: #bdbdbd;
      font-size: 20px;
    }

    .hero-left {
      text-align: left;
    }

    .hero-right {
      text-align: right;
      position: relative;
      padding-top: 50%;

      img {
        height: auto !important;

        @include media-breakpoint-down(lg) {
          height: 100% !important;
          width: auto !important;
          margin: 0 auto;
          margin-top: 32px;
        }
      }
    }

    @media (max-width: 767.98px) {
      h1 {
        font-size: 42px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  &--our-works {
    .our-works--grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 32px;

      @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
      }
    }
  }

  &--our-leadership {
    position: relative;
    .our-leadership--grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 32px;

      @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include media-breakpoint-down(md) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
      }

      .leader-avatar {
        width: 100px;
        height: 100px;
        position: relative;
        margin: 0 auto;
        margin-bottom: 22px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #e0e0e0;

        // img {
        //   width: 100%;
        //   height: auto;
        //   object-fit: cover;
        // }
      }

      .leader-name {
        font-weight: 600;
        font-size: 24px;
        text-align: center;
        margin-bottom: 5px;
      }

      .leader-description {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #e0e0e0;
        opacity: 0.8;
      }
    }
  }

  &--technology-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 6px;
    img {
      object-fit: contain;
      object-position: center;
      background-repeat: no-repeat;
    }
  }
}
