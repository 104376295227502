.card-grid {
  gap: 32px;
}

.card {
  --bs-card-color: #e0e0e0;
  --bs-card-bg: linear-gradient(
    101.21deg,
    rgba(24, 24, 24, 0.25) 0.52%,
    rgba(24, 24, 24, 0.25)
  );
  --bs-card-border-width: 1px;
  --bs-card-border-color: rgba(51, 51, 51, 0.5);
  --bs-card-border-radius: 15px;

  .card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    border-radius: 50px;
    margin-bottom: 20px;
    height: 52px;
    width: 52px;
    position: relative;

    img {
      max-width: 50%;
      max-height: 50%;
      margin: auto;
    }
  }

  h4 {
    margin-bottom: 8px;
  }
}

.card-works {
  .card-body {
    > p {
      margin-bottom: 0;
    }
  }
}
