.eec-news {
  &--hero {
    position: relative;
    padding: 118px 0 52px 0;
    margin-bottom: 80px;

    .hero {
      &__title {
        font-weight: 700;
        font-size: 56px;
        color: #fff;
      }

      @include media-breakpoint-down(lg) {
        &__title {
          font-size: 40px;
        }
      }
    }
  }

  &--grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @include media-breakpoint-down(lg) {
      gap: 32px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(md) {
      gap: 20px;
      grid-template-columns: 1fr;
    }
  }
}
