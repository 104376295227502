@import 'variables';
@import 'breakpoint';

@mixin fullWidth {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

@mixin linesLimit($maxLine) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $maxLine; /* number of lines to show */
  line-clamp: $maxLine;
  -webkit-box-orient: vertical;
}

@mixin layout() {
  max-width: 1260px;
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;

  @include media-breakpoint-down(lg) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include media-breakpoint-down(md) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@mixin alight-item-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin skeleton-load() {
  border-radius: 4px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: $darkest-grey;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba($color: $dark-grey, $alpha: 0) 0,
      rgba($color: $dark-grey, $alpha: 0.2) 20%,
      rgba($color: $dark-grey, $alpha: 0.5) 60%,
      rgba($color: $dark-grey, $alpha: 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
  @-moz-keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
  @-webkit-keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

@mixin withBorderRadius() {
  border-radius: $base-border-radius;

  @include media-breakpoint-down(lg) {
    border-radius: $base-border-radius-mobile;
  }
}

@mixin resetAnchor {
  color: inherit;
  text-decoration: none;
}

.highlight {
  background: linear-gradient(140deg, #91ffe4, #0c28a9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.eec-ghost-html-container {
  img {
    max-width: 100%;
    height: auto;
    width: 100%;
    border-radius: 16px;
  }

  figcaption {
    opacity: 0.5;
  }

  .eec-quote {
    position: relative;
    padding-left: 16px;
    // background-color: red;
    &::before {
      content: '';
      position: absolute;
      background-color: #3478f3;
      width: 4px;
      height: 100%;
      left: 0;
      border-radius: 2px;
    }
  }
}
