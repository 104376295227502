.gh-search {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  color: #ffffff;
  border: 0;
  outline: none;
}

.gh-search:hover {
  opacity: 0.9;
}
