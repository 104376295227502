$base-space: 8px;

$body-font: 'Inter', system-ui, -apple-system, 'Segoe UI', Roboto,
  'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$color-primary: #3478f3;
$color-secondary: #7c39ff;

$color-navy: #1c1624;
$color-body: #65606c;

$hamburger-padding-x: 10px;
$hamburger-padding-y: 5px;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 2px;

// bootstrap
$input-bg: transparent;

@import 'hamburgers/_sass/hamburgers/hamburgers.scss';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'react-tooltip/dist/react-tooltip.css';

@import './styles';

@import 'components/all';

:root {
  --color-primary: #{$color-primary};
  --color-secondary: #{$color-secondary};
  --bs-body-bg: #0a0a0a;
  --bs-body-color: #bdbdbd;
  --bs-card-color: #e0e0e0;
  --bs-card-bg: linear-gradient(
    101.21deg,
    rgba(24, 24, 24, 0.25) 0.52%,
    rgba(24, 24, 24, 0.25)
  );
  --bs-btn-border-radius: 64px;

  --bs-nav-link-color: #{$color-primary};
  --bs-link-hover-color: #{$color-primary};
}
