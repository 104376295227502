.our-mission {
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      margin-bottom: .825rem;
      padding-left: 30px;
      background: url("/assets/img/icon_check.svg") center left no-repeat;
      background-size: 16px 16px;
    }
  }
}
