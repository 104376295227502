.site-footer {
  background: linear-gradient(
    101.21deg,
    rgba(24, 24, 24, 0.25) 0.52%,
    rgba(24, 24, 24, 0.25) 100%
  );
  border-top: 1px solid rgba(51, 51, 51, 0.5);
  padding-bottom: 55px;
  padding-top: 55px;
  position: relative;

  .nav-link {
    padding: var(--bs-nav-link-padding-y) 0;
  }

  .logo {
    display: block;
    margin-bottom: 10px;
    margin-bottom: 32px;
    position: relative;
  }

  .copyright {
    color: #bdbdbd;
    font-size: 14px;
    margin-bottom: 32px;

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
    }
  }

  .translation {
    color: #bdbdbd;
    font-size: 14px;

    .goog-te-combo {
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      border: 1px solid #ffffff;
      border-radius: 50px;
      color: #ffffff;
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      padding: 10px 16px 10px 16px;
      text-align: center;
      margin-top: 0;

      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }

      option {
        background: linear-gradient(
          101.21deg,
          rgba(24, 24, 24, 0.25) 0.52%,
          rgba(24, 24, 24, 0.25) 100%
        );
        color: #000;
      }
    }
  }

  &--grid {
    --bs-gutter-y: 32px;
  }

  &--social {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-bottom: 32px;

    @media screen and (max-width: 768px) {
      margin-top: 32px;
    }
  }

  &--nav-title {
    color: #bdbdbd;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.01em;
    margin-bottom: 16px;
    opacity: 0.5;
  }
  &--nav-item {
    color: #bdbdbd;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.01em;
    margin-bottom: 16px;
  }
}
